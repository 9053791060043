import React, { useState } from 'react'
import {
  IconButton,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import { arInvoiceCols } from '../constants/ArCols'
import { smallScreenWidth } from '../constants/AppConstants'
import ServerSideAutoCompletev2 from '../functions/ServerSideAutoCompletev2'
import LoadingButton from '../components/LoadingButton'
import SimpleAutoComplete from '../functions/SimpleAutoComplete'
import ReactTable from '../components/ReactTable'
import LambdaFetch from '../functions/FetchFromLambda'
import ArInvoiceDetails from './ArInvoiceDetails'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: '2rem 1rem'
  },
  paper: {
    padding: theme.spacing(2),
    color: 'rgba(0,0,0,0.9)',
    height: '100%'
  },
  descript: { fontSize: '0.8rem', color: '#545454' },
  descriptVal: { fontSize: '0.85rem' }
}))

const defaultHeaders = {
  invoicePrefix: '',
  invoiceNumber: '',
  item: '',
  fromDate: '',
  toDate: ''
}

export default function ArInvoice (props) {
  const classes = useStyles()
  const { credentials } = props.fetchInitialData
  const [state, setstate] = useState({
    invoiceData: null,
    isLoading: false
  })
  const [headers, setHeaders] = useState(defaultHeaders)

  const handleSubmit = async e => {
    e.preventDefault()
    setstate({ ...state, isLoading: true })

    try {
    if (!headers.item && !(headers.invoicePrefix && headers.invoiceNumber)) {
      props.fetchInitialData.createSnack(
        'Please select an invoice or item',
        'warning',
        3000
      )
      throw new Error() 
    }

   
      const resp = await LambdaFetch(
        'ar-invoice',
        'post',
        credentials.user.accessToken,
        JSON.stringify({
          action: 'invoice-read-headers',
          ...headers
        }),
        '',
        credentials
      )
      setstate({ ...state, isLoading: false, 
        invoiceData: resp.data.invoices.map(i => {
        return {
            ...i,
             INVOICE_NUMBER: `${i.INVC_PREFIX}${i.INVC_NUMBER}`,
             VIEW: <div className='editLink' 
             onClick={() => {
                const win = window.open(
                    `/ar-invoice-details?company=1&prefix=${i.INVC_PREFIX}&invoice=${i.INVC_NUMBER}`,
                    '_blank'
                  )
                  win.focus()
             }}>view</div>   }
      })  })
    } catch (e) {
      setstate({ ...state, isLoading: false })
    }
  }
  return (
    <div style={{ padding: '1.5rem 0.5rem' }}>
      <IconButton
        style={{ borderRadius: '5px', position: 'absolute' }}
        size='small'
        onClick={() => props.history.push('/')}
        aria-label='delete'
      >
        <ArrowBackIcon />
        {props.fetchInitialData.credentials.appWidth > smallScreenWidth &&
          'Dashboard'}
      </IconButton>
      <Typography variant='h5' style={{ textAlign: 'center' }}>
        AR Invoice
      </Typography>

      <div className={classes.root}>
        <Paper elevation={0} style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              alignContent='center'
              alignItems='flex-end'
              spacing={2}
            >
              <Grid item>
                <SimpleAutoComplete
                  id={'invoicePrefix'}
                  name={'Prefix'}
                  width='100px'
                  required={false}
                  variant='standard'
                  label={'Prefix'}
                  value={headers.invoicePrefix}
                  onChange={val =>
                    setHeaders({ ...headers, invoicePrefix: val })
                  }
                  options={prefixes}
                  getOptionLabel={val => val.toString()}
                />
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  disabled={!headers.invoicePrefix}
                  id='invoiceNumber'
                  width='200px'
                  label='Invoice Number'
                  accessToken={credentials.user.accessToken}
                  error='No Invoice Found'
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, invoiceNumber: id })
                  }}
                  credentials={credentials}
                  apiResource='ar-invoice'
                  secondaryParams={{
                    action: 'autocomplete-invoice',
                    prefix: headers.invoicePrefix
                  }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ verticalAlign: 'bottom' }} variant='body1'>
                  OR
                </Typography>
              </Grid>
              <Grid item>
                <ServerSideAutoCompletev2
                  id='item'
                  width='220px'
                  label='Item'
                  accessToken={credentials.user.accessToken}
                  error={'No Item Found'}
                  variant='standard'
                  handleChange={(id, hit) => {
                    setHeaders({ ...headers, item: id })
                  }}
                  credentials={credentials}
                  apiResource='ar-invoice'
                  secondaryParams={{
                    action: 'autocomplete-invoice-item'
                  }}
                />
              </Grid>
              {[
                {
                  id: 'fromDate',
                  label: 'From Date'
                },
                {
                  id: 'toDate',
                  label: 'To Date'
                }
              ].map(field => {
                return (
                  <Grid key={field.id} item>
                    <TextField
                      id={field.id}
                      style={{ marginBottom: '5px', width: '160px' }}
                      type={'date'}
                      required={false}
                      label={field.label}
                      variant='standard'
                      value={headers.id}
                      fullWidth
                      onChange={e => {
                        setHeaders({
                          ...headers,
                          [field.id]: !!e.target.value ? e.target.value : null
                        })
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <span />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>

            <div style={{ marginTop: '1rem' }}>
              <LoadingButton
                label='Submit'
                isLoading={state.isLoading}
                color='primaryVLButton'
                buttonType='submit'
              />
            </div>
          </form>
        </Paper>

        {state.invoiceData && (
          <Grid
            item
            xs={12}
            style={{ position: 'relative', marginTop: '1rem' }}
          >
            <ReactTable cols={arInvoiceCols} data={state.invoiceData} />
          </Grid>
        )}
      </div>
    </div>
  )
}

const prefixes = [
  '33',
  '55',
  '66',
  '77',
  '79',
  '80',
  '99',
  'AG',
  'AK',
  'AS',
  'BC',
  'BI',
  'CA',
  'CH',
  'CL',
  'CP',
  'DF',
  'DH',
  'DJ',
  'DP',
  'DS',
  'EC',
  'EX',
  'FH',
  'FN',
  'HA',
  'HC',
  'HD',
  'HE',
  'HF',
  'HG',
  'HH',
  'HJ',
  'HK',
  'HT',
  'HV',
  'HX',
  'HZ',
  'KA',
  'LD',
  'MC',
  'MD',
  'MP',
  'MS',
  'PC',
  'PD',
  'PK',
  'PP',
  'PR',
  'PS',
  'PT',
  'PU',
  'PX',
  'PZ',
  'RC',
  'RD',
  'RF',
  'RR',
  'RU',
  'SA',
  'SC',
  'SN',
  'SP',
  'SR',
  'SS',
  'TJ',
  'TP',
  'TS',
  'TU',
  'XC',
  'XD',
  'ZZ'
]
